<template>
  <v-row justify="center">
    <v-dialog v-model="dialogIsOpen" persistent max-width="600">
      <v-card>
        <v-container class="justify-end d-flex">
          <v-btn icon @click="$emit('handleClose')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <v-card-title class="headline justify-center primary--text">
          {{ modalTitle }}
        </v-card-title>
        <v-card-text class="text-center">
          <v-row justify="center">
            <v-radio-group v-model="selectedOption" row @change="error = ''">
              <v-radio
                v-for="option in options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
                class="mx-6"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <div v-if="selectedOption" class="text-center">
            <v-row class="my-4" justify="center">
              <span class="font-weight-black primary--text mr-2">
                {{ $t('subscriptionModalDescriptionTitle') }}
                :</span
              >{{
                $tc('subscriptionModalDescription', selectedOption, {
                  count: selectedOption
                })
              }}
            </v-row>
            <v-row class="my-4" justify="center">
              <span class="font-weight-black primary--text mr-2"
                >{{ $t('subscriptionModalTotal') }}:
              </span>
              {{ price }} {{ $t('subscriptionModalCurrency') }}
            </v-row>
          </div>
        </v-card-text>

        <div class="text-center pb-6">
          <div v-if="error" class="error--text my-4">
            {{ error }}
          </div>
          <v-btn class="text-capitalize" color="primary" @click="checkout">
            {{ $t('checkout') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY)

export default Vue.extend({
  name: 'SubscriptionModal',
  props: {
    dialogIsOpen: {
      required: true,
      type: Boolean
    },
    modalTitle: {
      required: true,
      type: String
    },
    defaultSelectedOption: {
      required: true,
      type: String
    },
    options: {
      required: true,
      type: Array,
      default: () => ({
        label: String,
        value: String,
        price: String
      })
    }
  },
  data: () => ({
    selectedOption: null,
    error: ''
  }),
  computed: {
    price: function() {
      return this.options.find(option => option.value === this.selectedOption)
        .price
    }
  },
  created() {
    this.selectedOption = this.defaultSelectedOption
  },
  methods: {
    ...mapActions(['getCheckoutSession']),
    async checkout() {
      if (this.selectedOption) {
        const stripe = await stripePromise
        const session = await this.getCheckoutSession({
          duration: this.selectedOption
        }).then(res => {
          return res
        })
        const result = await stripe.redirectToCheckout({
          sessionId: session.stripe_checkout_session_id
        })

        if (result.error) {
          this.error = result.error.message
        }
      } else this.error = this.$t('subscriptionModalCheckoutClientError')
    }
  }
})
</script>
<style lang="scss"></style>
