<template>
  <v-container v-if="status.title" grid-list-s class="pa-10 col-md-8 col-sm-12">
    <h2 class="text-h4 font-weight-bold primary--text text-center">
      {{ status.title }}
    </h2>
    <div class="mt-4 mx-auto col-md-10 col-sm-12">
      {{ status.text }}
    </div>
    <v-row v-if="status.action" justify="center" class="my-4">
      <v-btn depressed color="primary" @click="status.action">
        {{ status.actionText }}
      </v-btn>
    </v-row>
    <div
      v-if="status.showProfileBtn"
      class="mt-4 mx-auto col-md-10 col-sm-12 center "
    >
      <v-btn :to="{ name: 'Profile' }" class="primary">{{
        $t('updateProfileBtn')
      }}</v-btn>
    </div>
    <SubscriptionModal
      default-selected-option="10"
      :dialog-is-open="isOpen"
      :modal-title="$t('makeSubscription')"
      :options="[
        { label: $t('tenYears'), value: '10', price: '750' },
        { label: $t('fiveYears'), value: '5', price: '400' },
        { label: $t('oneYear'), value: '1', price: '100' }
      ]"
      @handleClose="isOpen = false"
    />
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SubscriptionModal from '@/components/SubscriptionModal.vue'

export default Vue.extend({
  components: {
    SubscriptionModal
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapGetters({
      accountStatus: 'authStatus',
      authStatusEnum: 'authStatusEnum'
    }),
    status() {
      let info = { title: '', text: '', actionText: '', action: null }
      switch (this.accountStatus) {
        case this.authStatusEnum.AWAITING_CONFIRMATION:
          info.title = this.$t('accountPending')
          info.text = this.$t('accountPendingText')
          info.showProfileBtn = true
          break
        case this.authStatusEnum.AWAITING_PAYMENT:
          info.title = this.$t('accountPendingPayment')
          info.text = this.$t('accountPendingPaymentText')
          info.actionText = this.$t('accountPendingPaymentAction')
          info.action = this.handlePayment
          break
        case this.authStatusEnum.SUSPENDED:
          info.title = this.$t('accountSuspended')
          info.text = this.$t('accountSuspendedPaymentText')
          info.actionText = this.$t('accountSuspendedPaymentAction')
          info.action = this.handlePayment
          break
      }
      return info
    }
  },
  async created() {
    await this.getUserStatus()

    if (this.accountStatus === this.authStatusEnum.ACTIVE) {
      await this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    ...mapActions(['getUserStatus']),
    handlePayment() {
      this.isOpen = true
    }
  }
})
</script>
